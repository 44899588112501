import { useState, useEffect, useCallback } from "react";
import { FAQ } from "./components/FAQ";
import Header from "./components/Header";
import Footer from "./components/Footer";
import AboutUs from "./components/AboutUs";

export default function App() {
  const [darkMode, setdarkMode] = useState(false);
  const [feature, setfeature] = useState("Swap");

  useEffect(() => {
    const prefersDarkMode = window.matchMedia(
      "(prefers-color-scheme: dark)"
    ).matches;
    setdarkMode(prefersDarkMode);
  }, []);

  const SwapDescription = () => {
    return (
      <div className="dark:text-[#868686] leading-[140%] mt-4">
        Swap assets quickly and cost-effectively, enjoying the best prices. 
        
        <span className="text-main-orange dark:text-[#F88F6D] font-semibold">
        <br className="hidden lg:flex" /> <br />
        Our smart routing system&nbsp; 
        </span>
        combines liquidity from multiple sources, ensuring fast, low-cost trades. 
        <br className="hidden lg:flex" /> <br />
        Experience seamless trading and direct access to global assets, all without intermediaries or restrictions
        
        
      </div>
    );
  };
  const LiquidityDescription = () => {
    return (
      <div className="dark:text-[#868686] leading-[140%] mt-4">
        <span className="text-main-orange dark:text-[#F88F6D] font-semibold">
        Earn APY&nbsp; 
        </span>
         by adding liquidity to Soroswap’s decentralized pools. 
        <br className="hidden lg:flex" />
        <br></br>
        Your contributions help others swap tokens, and in return, you receive a share of the fees, becoming an essential part of the decentralized exchange ecosystem.
      </div>
    );
  };
  const SwapRouteAPIDescription = () => {
    return (
      <div className="dark:text-[#868686] leading-[140%] mt-4">
        Connect your wallet or DeFi protocol to our 
        <span className="text-main-orange dark:text-[#F88F6D] font-semibold">
        &nbsp; plug-and-play API&nbsp; 
        </span>
        and tap into our aggregation engine. 
        <br className="hidden lg:flex" />
        <br></br>
        Get the best prices and swapping routes by combining liquidity from across the entire ecosystem
      </div>
    );
  };
  const description = useCallback(() => {
    if (feature === "Swap") return SwapDescription();
    else if (feature === "Liquidity") return LiquidityDescription();
    else if (feature === "Swap-Route API") return SwapRouteAPIDescription();
    else return SwapDescription();
  }, [feature]);

  const getFeatureImg = () => {
    if (feature === "Swap") {
      return {
        dark: "/images/swap-dark.svg",
        light: "/images/swap-light.svg",
      };
    }
    if (feature === "Liquidity") {
      return {
        dark: "/images/liquidity-dark.svg",
        light: "/images/liquidity-light.svg",
      };
    }
    if (feature === "Swap-Route API") {
      return {
        dark: "/images/swap-dark.svg",
        light: "/images/swap-light.svg",
      };
    }
  };

  return (
    <div className={darkMode ? "dark" : ""}>
      <div className="text-main-black dark:text-white dark:bg-[#0F1016]">
        <div className="relative">
          <Header darkMode={darkMode} setdarkMode={setdarkMode} />

          {/* Hero Section Starts*/}
          <div className="relative max-w-[1400px] pl-5 pr-5 lg4:pr-0 lg2:pr-5 mx-auto flex lg4:items-center lg4:justify-between flex-col lg4:flex-row gap-7 lg4:gap-5 mt-10 z-30">
            <div>
              <div className="text-main text-sm tracking-[2px] mb-1 sm3:mb-0">
                HOME
              </div>
              <div className="titleFont text-[28px] sm5:text-[32px] sm4:text-[40px] sm3:text-[50px] sm:text-[60px] lg3:text-[70px] uppercase leading-[100%]">
              The first DEX
                <br />{" "}
                <span className="font-extrabold text-main-orange">
                  Aggregator
                </span>{" "}
                <span className="font-extrabold">on Stellar</span>
              </div>
              <div className="max-w-[600px] leading-[140%] text-[15px] sm4:text-[16px] dark:text-[#9292A3] mt-4">
              Unlocking financial access with a powerful exchange aggregator and our plug-and-play Swap-Route API—helping wallets and traders seamlessly integrate and access optimal liquidity.
              </div>
              <div className="items-center hidden gap-2 mt-10 lg4:flex">
                <a
                  href="https://app.soroswap.finance"
                  className="bg-main2 transition hover:bg-main hover:text-white rounded-[10px] text-[#E0E0E0] px-4 py-[10px]"
                >
                  {" "}
                  Launch App
                </a>
                <button
                  onClick={() =>
                    window.open("https://docs.soroswap.finance", "_blank")
                  }
                  className="group flex items-center gap-3 bg-transparent leading-none px-4 py-[10px]"
                >
                  Learn how to use{" "}
                  <img
                    src="/images/arrow.svg"
                    alt="arrow"
                    className="transition group-hover:translate-x-1"
                  />
                </button>
              </div>
            </div>
            <div className="relative lg4:translate-y-8 lg4:translate-x-3 lg2:translate-x-0">
              <img
                src="/images/hero-light.svg"
                alt="hero light"
                className="flex dark:hidden relative w-full sm:min-w-[450px] sm:max-w-[450px] lg3:min-w-[550px] lg3:max-w-[550px] z-20"
              />
              <img
                src="/images/hero-dark.svg"
                alt="hero dark"
                className="hidden dark:flex relative w-full sm:min-w-[450px] sm:max-w-[450px] lg3:min-w-[550px] lg3:max-w-[550px] z-20"
              />
              <img
                src="/images/circle1.svg"
                alt="circle bg"
                className="absolute -top-[120px] -left-[12rem]"
              />
            </div>
            <div className="flex items-center gap-4 lg4:hidden whitespace-nowrap sm4:gap-2">
              <a
                href="https://app.soroswap.finance"
                className="bg-main2 transition hover:bg-main hover:text-white rounded-[10px] text-[#E0E0E0] px-4 py-[10px]"
              >
                Launch App
              </a>
              <button
                onClick={() =>
                  window.open("https://soroban.stellar.org/", "_blank")
                }
                className="group flex items-center gap-3 bg-transparent leading-none px-0 sm4:px-4 py-[10px]"
              >
                Soroban Blockchain{" "}
                <img
                  src="/images/arrow.svg"
                  alt="arrow"
                  className="transition group-hover:translate-x-1"
                />
              </button>
            </div>
          </div>
          {/* background */}
          <img
            src="/images/waves.svg"
            alt="waves"
            className="dark:opacity-10 absolute top-[600px] lg4:top-[150px] left-0 w-full object-cover"
          />
          {/* Hero Section Ends*/}

          {/* Metrics Section Starts*/}
          {/* <div className={`relative border-y border-[#4a25a72e] dark:border-[#8866dd33] w-full mt-[50px] lg4:mt-[100px] z-30 ${darkMode ? "gradientDark" : "gradient"}`}>
            <div className="flex items-center justify-between w-full gap-3 py-4 leading-none text-center mainCont md:justify-evenly whitespace-nowrap sm3:py-5">
              <div className="flex flex-col items-center justify-center gap-3">
                <div className="text-[#4E4E4E] dark:text-white text-[18px] sm3:text-[24px] sm:text-[27px] font-semibold">$1.3 T+</div>
                <div className="text-[#EA430B] text-[13px] sm3:text-[15px] font-medium">SWAP</div>
              </div>

              <div className="flex flex-col items-center justify-center gap-3">
                <div className="text-[#4E4E4E] dark:text-white text-[18px] sm3:text-[24px] sm:text-[27px] font-semibold">$160 M+</div>
                <div className="text-[#EA430B] text-[13px] sm3:text-[15px] font-medium">Polls</div>
              </div>

              <div className="flex flex-col items-center justify-center gap-3">
                <div className="text-[#4E4E4E] dark:text-white text-[18px] sm3:text-[24px] sm:text-[27px] font-semibold">1M+</div>
                <div className="text-[#EA430B] text-[13px] sm3:text-[15px] font-medium">Users</div>
              </div>

              <div className="flex flex-col items-center justify-center gap-3">
                <div className="text-[#4E4E4E] dark:text-white text-[18px] sm3:text-[24px] sm:text-[27px] font-semibold">300+</div>
                <div className="text-[#EA430B] text-[13px] sm3:text-[15px] font-medium">Integrations</div>
              </div>
            </div>
          </div> */}
          {/* Metrics Section Ends*/}

          {/* background */}
          <img
            src="/images/glitch.png"
            alt="glitch"
            className="absolute top-0 left-0 object-cover w-full h-full"
          />
        </div>

        {/* Services Section Starts*/}
        <div className="relative z-10">
          <div className="relative mainCont">
            <div id="services" className="relative z-30 pt-5 mt-14">
              <div className="text-main text-sm uppercase tracking-[2px]">
                Services
              </div>
              <div className="titleFont mt-1 sm:mt-0 text-[35px] sm6:text-[40px] sm:text-[50px] uppercase leading-[100%]">
                Discover <span className="font-extrabold">our</span>{" "}
                <span className="font-extrabold text-main-orange">
                  features
                </span>
              </div>
              <div
                className={`relative border border-[#ff8f6d1a] dark:border-[#966ff61a] rounded-[25px] overflow-hidden px-5 py-5 md:py-8 mt-8 ${
                  darkMode ? "gradientCardDark" : "gradientCard"
                }`}
              >
                <div className="relative z-20">
                  {/* tabs */}
                  <div className="flex items-center gap-3 p-2 mx-auto rounded-full bg-main bg-opacity-10 w-fit">
                    <button
                      onClick={() => {
                        setfeature("Swap");
                      }}
                      className={`font-semibold text-[15px] rounded-full transition px-3 sm:px-4 py-[3px] ${
                        feature === "Swap"
                          ? "bg-main hover:bg-main2 text-[#E0E0E0]"
                          : ""
                      }`}
                    >
                      Swap
                    </button>
                    <button
                      onClick={() => {
                        setfeature("Liquidity");
                      }}
                      className={`font-semibold text-[15px] rounded-full transition px-3 sm:px-4 py-[3px] ${
                        feature === "Liquidity"
                          ? "bg-main hover:bg-main2 text-[#E0E0E0]"
                          : ""
                      }`}
                    >
                      Liquidity
                    </button>
                    <button
                      onClick={() => {
                        setfeature("Swap-Route API");
                      }}
                      className={`font-semibold text-[15px] rounded-full transition px-3 sm:px-4 py-[3px] ${
                        feature === "Swap-Route API"
                          ? "bg-main hover:bg-main2 text-[#E0E0E0]"
                          : ""
                      }`}
                    >
                      Swap-Route API
                    </button>
                  </div>

                  <div className="flex md:items-center flex-col-reverse md:flex-row gap-10 max-w-[900px] mx-auto mt-5 md:mt-12">
                    <img
                      src={getFeatureImg().light}
                      alt="swap"
                      className="flex dark:hidden rounded-[13px] w-[350px] md:min-w-[350px] md:max-w-[350px] lg:min-w-[400px] lg:max-w-[400px] mx-auto md:mx-0"
                    />
                    <img
                      src={getFeatureImg().dark}
                      alt="swap"
                      className="hidden dark:flex rounded-[13px] w-[350px] md:min-w-[350px] md:max-w-[350px] lg:min-w-[400px] lg:max-w-[400px] mx-auto md:mx-0"
                    />
                    <div>
                      <div className="titleFont text-[45px] font-bold">
                        {feature}
                      </div>
                      {description()}

                      <br></br>
                      <a
                        href="https://app.soroswap.finance"
                        className="bg-main2 transition hover:bg-main hover:text-white rounded-[10px] text-[#E0E0E0] px-[20px] py-[10px] mt-10 lg:mt-14"
                      >
                        Launch App
                      </a>
                    </div>
                  </div>
                </div>
                <img
                  src="/images/bg1.svg"
                  alt="bg"
                  className="absolute top-0 left-0 object-cover h-full dark:grayscale"
                />
              </div>
            </div>

            <img
              src="/images/circle2.svg"
              alt="circle bg"
              className="flex dark:hidden w-[700px] absolute -top-32 -left-[200px]"
            />
            <img
              src="/images/circle9.svg"
              alt="circle bg"
              className="hidden dark:flex w-[700px] absolute -top-32 -left-[200px]"
            />
          </div>
          <img
            src="/images/wave1.svg"
            alt="wave bg"
            className="flex dark:hidden absolute -bottom-[150px] md:-bottom-[250px] left-0 w-full"
          />
          <img
            src="/images/wave3.svg"
            alt="wave bg"
            className="hidden dark:flex absolute -bottom-[150px] md:-bottom-[250px] left-0 w-full"
          />
        </div>
        {/* Services Section Ends*/}

        <div className="relative bg-[#D1D1D1] dark:bg-[#000000] dark:bg-opacity-20 bg-opacity-20 mt-[150px] md:mt-[250px] overflow-hidden">
          {/* Benefits Section Started */}
          <div className="mainCont relative w-full pt-[30px] z-30">
            <div id="benefits" className="relative z-20 pt-5">
              <div className="text-main text-sm text-center uppercase tracking-[2px]">
                Benefits
              </div>
              <div className="titleFont mt-1 sm3:mt-0 text-[35px] sm4:text-[40px] sm3:text-[50px] text-center uppercase leading-[100%]">
                Why <span className="font-extrabold text-main-orange">use</span>{" "}
                <span className="font-extrabold">Soroswap</span>
              </div>

              <div className="mt-8 sm3:mt-12">
                <div className="grid grid-cols-1 gap-3 mb-5 md:grid-cols-2">
                  <div
                    className={`rounded-lg p-5 ${
                      darkMode ? "gradientCardDark2" : "gradient2"
                    }`}
                  >
                    <div className="text-[23px] font-semibold dark:text-[#E5E5E5] mb-2">
                      Fast
                    </div>
                    <div className="text-[#4E4E4E] dark:text-[#868686] font-medium">
                    Lightning-fast swaps powered by Stellar and our aggregation engine.
                    </div>
                  </div>

                  <div
                    className={`rounded-lg p-5 ${
                      darkMode ? "gradientCardDark2" : "gradient2"
                    }`}
                  >
                    <div className="text-[23px] font-semibold dark:text-[#E5E5E5] mb-2">
                      Best Prices
                    </div>
                    <div className="text-[#4E4E4E] dark:text-[#868686] font-medium">
                    Connect our plug-and-play API and start getting the best quotes
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-1 gap-3 md:grid-cols-3">
                  <div
                    className={`rounded-lg p-5 ${
                      darkMode ? "gradientCardDark2" : "gradient2"
                    }`}
                  >
                    <div className="text-[23px] font-semibold dark:text-[#E5E5E5] mb-2">
                      Cross - border
                    </div>
                    <div className="text-[#4E4E4E] dark:text-[#868686] font-medium">
                      Soroban on Stellar enables global DeFi access
                    </div>
                  </div>

                  <div
                    className={`rounded-lg p-5 ${
                      darkMode ? "gradientCardDark2" : "gradient2"
                    }`}
                  >
                    <div className="text-[23px] font-semibold dark:text-[#E5E5E5] mb-2">
                      Permissionless
                    </div>
                    <div className="text-[#4E4E4E] dark:text-[#868686] font-medium">
                      Anyone with a Stellar wallet can join directly or via our plug-and-play API
                    </div>
                  </div>

                  <div
                    className={`rounded-lg p-5 ${
                      darkMode ? "gradientCardDark2" : "gradient2"
                    }`}
                  >
                    <div className="text-[23px] font-semibold dark:text-[#E5E5E5] mb-2">
                      Decentralized
                    </div>
                    <div className="text-[#4E4E4E] dark:text-[#868686] font-medium">
                      Direct token swaps via smart contracts, no intermediaries.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <img
              src="/images/circle5.svg"
              alt="circle bg"
              className="absolute top-5 -right-24 w-[600px] dark:opacity-60"
            />
            {/* Benefits Section Ends */}

            {/* Community Section Starts*/}
            <div
              id="community"
              className="relative pt-5 mt-[100px] md:mt-[150px] pb-14 z-30"
            >
              <div className="relative z-20">
                <div className="text-main text-sm uppercase tracking-[2px]">
                  Community
                </div>
                <div className="titleFont mt-1 lg4:mt-0 text-[40px] lg4:text-[50px] uppercase leading-[100%]">
                  Discover more of <span className="font-extrabold">our</span>{" "}
                  <span className="font-extrabold text-main-orange">
                    community
                  </span>
                </div>
                <div className="grid gird-cols-1 lg4:grid-cols-[400px,1fr] gap-3 mt-8">
                  <div
                    className={`relative flex items-start justify-between flex-col rounded-lg min-h-[300px] lg4:min-h-auto p-5 ${
                      darkMode ? "gradientCardDark2" : "gradient2"
                    }`}
                  >
                    <div className="relative z-20">
                      <div className="text-[28px] font-semibold dark:text-[#E5E5E5] mb-2">
                        Community
                      </div>
                      <div className="text-[#4E4E4E] dark:text-[#868686] font-medium">
                        Everything we do is open-source and transparent.
                      </div>
                    </div>
                    <button
                      onClick={() =>
                        window.open("https://discord.gg/yXFaku4w9u", "_blank")
                      }
                      className="relative bg-main2 transition hover:bg-main hover:text-white flex items-center gap-2 ml-auto rounded-[10px] text-[#E0E0E0] px-[20px] py-[10px] mt-14 z-20"
                    >
                      Discord{" "}
                      <svg
                        width="11"
                        height="11"
                        viewBox="0 0 10 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M2.02174 1.48089L8.38468 1.36658L8.49898 7.72952M7.95075 1.81639L1.61533 8.38359"
                          stroke="white"
                          strokeWidth="1.40625"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </button>
                    <img
                      src="/images/globe.svg"
                      alt="globe"
                      className="absolute left-0 h-full -translate-y-1/2 top-1/2"
                    />
                  </div>

                  <div className="flex flex-col gap-3">
                    <button
                      onClick={() =>
                        window.open("https://twitter.com/SoroswapFinance", "_blank")
                      }
                      className={`relative group flex items-start justify-between flex-col rounded-lg p-5 ${
                        darkMode ? "gradientCardDark2" : "gradient2"
                      }`}
                    >
                      <div className="flex items-center gap-3 text-[28px] font-semibold dark:text-[#E5E5E5] mb-2">
                        Twitter{" "}
                        <svg
                          width="10"
                          height="11"
                          className="translate-y-[2px] transition group-hover:translate-x-2"
                          viewBox="0 0 12 13"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1.53261 1.28359L11.077 1.11213L11.2485 10.6565M10.4261 1.78684L0.922992 11.6376"
                            stroke={darkMode ? "#E5E5E5" : "#181A25"}
                            strokeWidth="1.40625"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                      <div className="text-[#4E4E4E] dark:text-[#868686] font-medium">
                        Stay on top of the latest updates
                      </div>
                    </button>

                    <button
                      onClick={() =>
                        window.open("https://github.com/soroswap/", "_blank")
                      }
                      className={`relative group flex items-start justify-between flex-col rounded-lg p-5 ${
                        darkMode ? "gradientCardDark2" : "gradient2"
                      }`}
                    >
                      <div className="flex items-center gap-3 text-[28px] font-semibold dark:text-[#E5E5E5] mb-2">
                        GitHub{" "}
                        <svg
                          width="10"
                          height="11"
                          className="translate-y-[2px] transition group-hover:translate-x-2"
                          viewBox="0 0 12 13"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1.53261 1.28359L11.077 1.11213L11.2485 10.6565M10.4261 1.78684L0.922992 11.6376"
                            stroke={darkMode ? "#E5E5E5" : "#181A25"}
                            strokeWidth="1.40625"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                      <div className="text-[#4E4E4E] dark:text-[#868686] font-medium">
                        Everything we do is open source, follow our codes on
                        github
                      </div>
                    </button>

                    <button
                      onClick={() =>
                        window.open("https://stellar.org/", "_blank")
                      }
                      className={`relative group flex items-start justify-between flex-col rounded-lg p-5 ${
                        darkMode ? "gradientCardDark2" : "gradient2"
                      }`}
                    >
                      <div className="flex items-center gap-3 text-[28px] font-semibold dark:text-[#E5E5E5] mb-2">
                        Stellar{" "}
                        <svg
                          width="10"
                          height="11"
                          className="translate-y-[2px] transition group-hover:translate-x-2"
                          viewBox="0 0 12 13"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1.53261 1.28359L11.077 1.11213L11.2485 10.6565M10.4261 1.78684L0.922992 11.6376"
                            stroke={darkMode ? "#E5E5E5" : "#181A25"}
                            strokeWidth="1.40625"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                      <div className="text-[#4E4E4E] dark:text-[#868686] font-medium">
                        Learn more about the blockchain Stellar
                      </div>
                    </button>
                  </div>
                </div>
              </div>

              <img
                src="/images/circle4.svg"
                alt="circle bg"
                className="absolute -top-2 -left-10 lg4:left-0 w-[500px]"
              />
              <img
                src="/images/circle6.svg"
                alt="circle bg"
                className="absolute -bottom-[200px] -right-[150px] w-[700px] dark:opacity-80"
              />
            </div>
            {/* Community Section Ends*/}

            {/* background lines */}
            <div className="absolute top-0 left-1/2 -translate-x-1/2 w-[calc(100%-40px)] h-full flex items-center justify-between">
              <div className="w-[1px] h-full bg-[#B9B8FF] bg-opacity-20 dark:bg-opacity-10"></div>
              <div className="w-[1px] h-full bg-[#B9B8FF] bg-opacity-20 dark:bg-opacity-10"></div>
              <div className="w-[1px] h-full bg-[#B9B8FF] bg-opacity-20 dark:bg-opacity-10 hidden md:flex"></div>
              <div className="w-[1px] h-full bg-[#B9B8FF] bg-opacity-20 dark:bg-opacity-10 hidden md:flex"></div>
              <div className="w-[1px] h-full bg-[#B9B8FF] bg-opacity-20 dark:bg-opacity-10 hidden md:flex"></div>
              <div className="w-[1px] h-full bg-[#B9B8FF] bg-opacity-20 dark:bg-opacity-10"></div>
              <div className="w-[1px] h-full bg-[#B9B8FF] bg-opacity-20 dark:bg-opacity-10"></div>
            </div>
          </div>
        </div>

        {/* Connect Wallet Section Starts*/}
        <div className="relative w-full overflow-hidden bg-black">
          <div className="relative z-30 flex flex-col items-center justify-center w-full py-5 text-center mainCont">
            <div className="titleFont text-[40px] sm:text-[55px] uppercase tracking-wide text-white font-extrabold mb-2 sm:mb-1">
              Start now
            </div>
            <div className="text-white dark:text-[#868686] text-[15px] sm:text-[17px] mb-4">
              Connect your Stellar Wallet and start trading
            </div>
            <button
              onClick={() => window.open("https://stellar.org/", "_blank")}
              className="group flex items-center gap-3 w-fit mx-auto text-[#4E4E4E] dark:text-[#F88F6D] mb-7"
            >
              More about the stellar blockchain{" "}
              <svg
                width="11"
                height="11"
                className="transition group-hover:translate-x-[2px]"
                viewBox="0 0 13 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.03261 1.78359L11.577 1.61213L11.7485 11.1565M10.9261 2.28684L1.42299 12.1376"
                  stroke="currentColor"
                  strokeWidth="1.40625"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
            <a
              href="https://app.soroswap.finance"
              className="bg-main2 transition hover:bg-main hover:text-white rounded-[10px] text-[#E0E0E0] flex w-fit mx-auto px-4 py-[10px]"
            >
              Launch App
            </a>
          </div>
          <img
            src="/images/bg2.png"
            alt="bg"
            className="absolute top-0 left-0 object-cover w-full h-full dark:opacity-20"
          />
          <img
            src="/images/waves2.svg"
            alt="waves"
            className="absolute top-0 left-0 object-cover w-full h-full dark:opacity-20"
          />
        </div>
        {/* Connect Wallet Section Ends*/}
        <div className="relative overflow-hidden">
          {/* About Us Section Starts*/}
          <div className="mainCont relative z-30 pt-[80px]">
            <AboutUs className='relative max-w-[1400px] pl-5 pr-5 lg4:pr-0 lg2:pr-5 mx-auto flex shrink lg4:items-center lg4:justify-between flex-col lg4:flex-row gap-7 lg4:gap-5 mt-10 z-30' darkMode={darkMode} setdarkMode={setdarkMode}/>
            {/* About Us Section Ends*/}

            {/* FAQ Section Starts*/}
            <div
              id="faq"
              className="relative pt-5 z-20 pb-[50px] sm:pb-[100px] overflow-hidden mt-20 sm:mt-36"
            >
              <div className="text-main text-sm text-center uppercase tracking-[2px]">
                FAQ
              </div>
              <div className="titleFont mt-1 md:mt-0 text-[35px] sm2:text-[40px] md:text-[50px] text-center uppercase leading-[100%]">
                <span className="font-extrabold text-main-orange">
                  Frequently
                </span>{" "}
                <span className="font-extrabold">asked</span> questions
              </div>
              <div
                className={`relative rounded-[18px] max-w-[800px] mx-auto p-5 mt-8 sm:mt-14 ${
                  darkMode ? "gradient3" : "faqBG"
                }`}
              >
                <div className="relative z-10">
                  <FAQ />
                </div>
                <img
                  src="/images/circle8.svg"
                  alt="circle"
                  className="absolute -top-[80px] md:-top-[150px] left-0 md:-left-16 w-[800px] md:w-[1000px] dark:opacity-50"
                />
              </div>
            </div>
            {/* FAQ Section Ends*/}
          </div>

          <img
            src="/images/glitch2.png"
            alt="glitch"
            className="absolute top-5 left-0 w-full h-[calc(100%-20px)] object-cover object-top"
          />
          <img
            src="/images/bg3.svg"
            alt="bg"
            className="flex dark:hidden absolute bottom-0 right-0 h-[1200px] object-cover"
          />
          <img
            src="/images/bg4.svg"
            alt="bg"
            className="hidden dark:flex absolute bottom-0 right-0 h-[1300px] object-cover"
          />
        </div>
      </div>

      <Footer />
    </div>
  );
}
