import React, {useState} from 'react'
import VerticalCarousel from './VerticalCarousel';

function InfoSection(props) {
  const {data} = props;
  return (
    <>
      <div className="mt-5 lg5:mt-0 lg5:-translate-y-4 max-w-[480px] lg5:max-w-[650px] mx-auto lg5:mx-0">
        <div className="titleFont text-[32px] sm6:text-[40px] font-bold leading-tight sm6:leading-normal mb-2 sm6:mb-0">
          {data.fullName} ({data.alias})
        </div>
        <div>
          {data.ocupation}
        </div>
        <br />
        <div className="dark:text-[#868686] mb-10">
          <p>
            {data.content.description.paragraph1}
          </p>
          <br />
          <br />
          <p>
            {data.content.description.paragraph2}
          </p>
        </div>
        <div className="flex items-center gap-2 sm6:gap-5 text-[15px] flex-wrap sm6:text-[16px]">
          <button onClick={() => window.open(`${data.content.urls.web}`,"_blank")}
                  className="relative bg-main2 transition hover:bg-main hover:text-white flex items-center gap-2 rounded-[10px] text-[#E0E0E0] px-[20px] py-[10px] z-20"
          >
            Website{" "}
            <img src="/images/website.svg" alt="website" className="w-[20px]"/>
          </button>
          <button onClick={() => window.open(`${data.content.urls.linkedIn}`,"_blank")}
                  className="relative bg-main2 transition hover:bg-main hover:text-white flex items-center gap-2 rounded-[10px] text-[#E0E0E0] px-[20px] py-[10px] z-20"
          >
            Linkedin{" "}
            <img src="/images/linkedin.svg" alt="linkedin" className="w-[15px]"/>
          </button>
          <button onClick={() => window.open(`${data.content.urls.gitHub}`,"_blank")}
                  className="relative bg-main2 transition hover:bg-main hover:text-white flex items-center gap-2 rounded-[10px] text-[#E0E0E0] px-[20px] py-[10px] z-20"
          >
            Github{" "}
            <img src="/images/github.svg" alt="github" className="w-[20px]"/>
          </button>
        </div>
      </div>
    </>
  )
}


function AboutUs(darkMode) {
  const data = {
    "leadingText": "All about",
    "items": [
      {
        "alias": "esteblock",
        "ocupation": "Founder & CEO / Senior Full-stack Blockchain Developer",
        "fullName": "Esteban Iglesias",
        "content": {
          "image": "/images/person1.png",
          "description": {
            "paragraph1": "As a self-motivated, versatile, and polyglot professional with over 10 years of experience in full-stack development, I am passionate about innovation, decentralization, and the power of blockchain technology.",
            "paragraph2": "I am a Chilean with a background in electrical engineering and a master’s degree in Applied Economics with studies in France (École Centrale Paris), UK (University of Oxford), and Chile (Universidad de Chile). I am graduated from the Polkadot Blockchain Academy.",
          },
          "urls": {
            "web": "https://esteban.paltalabs.io/",
            "gitHub": "https://github.com/esteblock",
            "linkedIn": "https://linkedin.com/in/esteban-iglesias"
          }
        }
      },
      {
        "alias": "devmonsterblock",
        "ocupation": "Co-Founder & CPO / Senior Full-stack Blockchain Developer",
        "fullName": "Francisco Catrileo",
        "content": {
          "image": "/images/person2.png",
          "description": {
            "paragraph1": "Driven by curiosity and a passion for blockchain technology, I am an experienced (6 years) full-stack developer with expertise in Soroban, Ethereum, Solana and Avalanche platforms.",
            "paragraph2": "My academic foundation includes an Electrical Engineering degree from Universidad de Chile and General Engineering from Ecole CentraleSupelec, France. When not coding, I am balancing on my hands.",
          },
          "urls": {
            "web": "https://francisco.paltalabs.io/",
            "gitHub": "https://github.com/chopan123",
            "linkedIn": "https://www.linkedin.com/in/francisco-catrileo-3908646a/"
          }
        }
      },
      {
        "alias": "yripper",
        "ocupation": "Co-Foudner & CTO / Full-stack Blockchain Developer",
        "fullName": "Joaquin Soza",
        "content": {
          "image": "/images/person3.png",
          "description": {
            "paragraph1": "As a dedicated blockchain developer, I’ve immersed myself in this dynamic field for over 4 years.",
            "paragraph2": "My self-taught journey began at the age of 14, starting with coding and Linux. My technical skills extend beyond software development, encompassing soldering and microcontrollers.",
          },
          "urls": {
            "web": "https://joaquin.paltalabs.io/",
            "gitHub": "https://github.com/joaquinsoza",
            "linkedIn": "https://www.linkedin.com/in/joaquinsoza"
          }
        }
      },
      /* {
        "alias": "abstract",
        "ocupation": "Full-stack Developer",
        "fullName": "Maximiliano Carrasco",
        "content": {
          "image": "/images/person4.png",
          "description": {
            "paragraph1": "I am a web developer with skills in frontend development. With experience working for both companies and on freelance projects",
            "paragraph2": "I have tackled a wide range of web projects, from creating robust web applications to implementing bug fixes and optimizations.",
          },
          "urls": {
            "web": "https://maximiliano.paltalabs.io/",
            "gitHub": "https://www.github.com/abstract829",
            "linkedIn": "https://www.linkedin.com/in/maximiliano-carrasco-886861201/"
          }
        }
      }, */
      {
        "alias": "JennyT.eth",
        "ocupation": "Community Manager",
        "fullName": "Jenny Tejedor",
        "content": {
          "image": "/images/person6.png",
          "description": {
            "paragraph1": "I’m a psychologist from the University of Buenos Aires, specializing in technology and digital transition. With 5 years of experience in Web3 and blockchain",
            "paragraph2": "I manage community engagement and business development at PaltaLabs 🥑, driving growth in these areas. I’m passionate about learning and staying updated on the latest innovations in the ecosystem.",
          },
          "urls": {
            "web": "https://linktr.ee/JennyT.eth",
            "gitHub": "https://github.com/JennyT3",
            "linkedIn": "https://www.linkedin.com/in/jennytejedor/"
          }
        }
      },
      {
        "alias": "Neo",
        "ocupation": "Full-stack Developer",
        "fullName": "Matias Poblete",
        "content": {
          "image": "/images/person5.png",
          "description": {
            "paragraph1": "Driven by my passion for technology, I became a self-taught web developer. I am a knowledge enthusiast and always seek new opportunities to learn and grow",
            "paragraph2": "Currently, I have the pleasure of working with the Paltalabs 🥑 team. In this role, I am developing within the Stellar ecosystem to contribute to building a decentralized future. ",
          },
          "urls": {
            "web": "https://matias.paltalabs.io/",
            "gitHub": "https://www.github.com/MattPoblete",
            "linkedIn": "https://www.linkedin.com/in/matias-poblete-duran/"
          }
        }
      },
    ]
  }
  const [currentItem, setCurrentItem] = useState(0);
  
  return (
    <>
      {/* Inicio de la seccion about us */}
      <div id="about-us" className="relative z-20 pt-5">
        {/* Titulos*/}
        <div className="text-main text-sm text-center uppercase tracking-[2px]">
          About us
        </div>
        <div className="titleFont mt-1 sm7:mt-0 text-[40px] sm4:text-[50px] text-center uppercase leading-[100%]">
          Meet{" "}
          <span className="font-extrabold text-main-orange">our</span>{" "}
          <span className="font-extrabold">team</span>
        </div>
        {/* Carrusel del equipo */}
        <div className="mt-8 sm3:mt-14">
          <div className="flex flex-col lg5:gap-10 lg5:flex-row ">
            <VerticalCarousel data={data.items} darkMode={darkMode.darkMode} setCurrentItem={setCurrentItem} currentItem={currentItem}/>
            <div className="items-center flex lg:hidden gap-2 mx-auto w-fit mt-7">
            {data.items.map((item, i) => (
              <button onClick={() => {setCurrentItem(i);}} 
                      className={`w-[10px] h-[10px] rounded-full transition ${currentItem === i ? "bg-main-orange": "bg-[#868686]"}`}/>
            ))}
          </div>
            <InfoSection data={data.items[currentItem]}/>
          </div>
          <div className="items-center hidden gap-2 mx-auto lg5:flex w-fit mt-7">
            {data.items.map((item, i) => (
              <button onClick={() => {setCurrentItem(i);}} 
                      className={`w-[10px] h-[10px] rounded-full transition ${currentItem === i ? "bg-main-orange": "bg-[#868686]"}`}/>
            ))}
          </div>
        </div>
      </div>
      <img src="/images/circle7.svg" alt="circle" className="absolute top-[30px] -left-[120px] h-[1000px]"/>
    </>
  )
}

export default AboutUs